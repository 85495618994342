// @flow

import * as React from "react";
import { Document, PDFViewer } from "@react-pdf/renderer";
import {
  compose,
  lifecycle,
  setDisplayName,
  withHandlers,
  withProps,
  withState
} from "recompose";
import { getAbbreviationForProductionJobMethod } from "../../helpers/getAbbreviationForProductionJobMethod";
import { getAbbreviationForProductionJobMethodLocation } from "../../helpers/getAbbreviationForProductionJobMethodLocation";
import ExpeditingLabel from "./components/ExpeditingLabel";

import type { HOC } from "recompose";

type Props = {|
  +autoPrint?: boolean,
  +hidden: boolean,
  +isArtworkApproved: boolean,
  +isResolution?: boolean,
  +printRef: string,
  +priority?: boolean,
  +productionJobs: Array<string>,
  +productionStationAssignment?: string,
  +shipMissCosts?: Array<{|
    +cost: {
      +formatted: string
    },
    +date: string
  |}>,
  +totalProductionJobs?: number
|};

const enhancer: HOC<*, Props> = compose(
  setDisplayName("ExpeditingLabelViewer"),

  withState("shouldAutoPrint", "setShouldAutoPrint", ({ autoPrint }) =>
    autoPrint ? autoPrint : false
  ),
  withState("rendered", "setRendered", false),

  withProps(({ productionJobs }) => {
    if (productionJobs) {
      return {
        productionJobs: productionJobs.map(productionJob => ({
          ...productionJob,
          printLocationsByMethod: productionJob.customizationAreas.reduce(
            (accumulator, customizationArea) => {
              const method = getAbbreviationForProductionJobMethod(
                customizationArea.method
              );
              const location = getAbbreviationForProductionJobMethodLocation(
                customizationArea.location
              );
              const a = accumulator.find(i => i.method === method);
              if (a) {
                a.locations.push(location);
              } else {
                accumulator.push({
                  method: method,
                  locations: [location]
                });
              }
              return accumulator;
            },
            []
          )
        }))
      };
    }
  }),

  withProps(({ hidden, style }) => {
    if (hidden) {
      return { style: { ...style, display: "none" } };
    }
  }),

  withHandlers(() => {
    let pdfRef;
    return {
      handlePdfRef: () => ref => {
        pdfRef = ref;
      },
      handlePrint:
        ({ rendered }) =>
        () => {
          console.log("handlePrint - rendered"); // eslint-disable-line no-console
          console.log(rendered); // eslint-disable-line no-console
          console.log("handlePrint- pdfRef"); // eslint-disable-line no-console
          console.log(pdfRef); // eslint-disable-line no-console
          if (rendered && pdfRef) {
            pdfRef.focus();
            (pdfRef.contentWindow || pdfRef.contentDocument).print();
          }
        },
      handleRender:
        ({ autoPrint, setRendered, setShouldAutoPrint, shouldAutoPrint }) =>
        () => {
          setTimeout(() => {
            setRendered(true);
            console.log("handleRender - autoPrint"); // eslint-disable-line no-console
            console.log(autoPrint); // eslint-disable-line no-console
            console.log("handleRender - shouldAutoPrint"); // eslint-disable-line no-console
            console.log(shouldAutoPrint); // eslint-disable-line no-console
            console.log("handleRender- pdfRef"); // eslint-disable-line no-console
            console.log(pdfRef); // eslint-disable-line no-console
            if (shouldAutoPrint) {
              pdfRef.focus();
              (pdfRef.contentWindow || pdfRef.contentDocument).print();
              setShouldAutoPrint(false);
            }
          }, 1000);
        }
    };
  }),
  lifecycle({
    componentDidMount() {
      const { printRef, handlePrint } = this.props;
      console.log("printRef"); // eslint-disable-line no-console
      console.log(printRef); // eslint-disable-line no-console
      if (printRef) {
        printRef(handlePrint);
      }
    }
  })
);

const ExpeditingLabelViewer = ({
  handlePdfRef,
  handleRender,
  isResolution,
  priority,
  productionJobs,
  productionStationAssignment,
  shipMissCosts,
  style,
  totalProductionJobs,
  includeCatalogInShipment
}) => (
  <PDFViewer innerRef={handlePdfRef} style={style} width="100%" height="1000">
    <Document onRender={handleRender}>
      {productionJobs.map(productionJob => (
        <ExpeditingLabel
          key={productionJob.id}
          isResolution={isResolution || productionJob?.order?.isResolution}
          priority={priority || productionJob?.order?.priority}
          includeCatalogInShipment={includeCatalogInShipment || productionJob?.order?.includeCatalogInShipment}
          shipMissCosts={shipMissCosts}
          productionJob={productionJob}
          productionStationAssignment={productionStationAssignment}
          totalProductionJobs={totalProductionJobs}
        />
      ))}
    </Document>
  </PDFViewer>
);
export default enhancer(ExpeditingLabelViewer);
